
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'
import { useRouter } from 'vue-router'
import { ips } from '@/definitions/admin/workspace/security/data'

export default defineComponent({
  components: {
    TmButton,
    TmBadge,
    InfoCard,
  },
  setup() {
    const router = useRouter()
    const enable2fa = ref(window.location.search.includes('2fa'))

    const { openChangeAuthenticationModal, openBackupVerificationCodesModal, openDisableTwoFactorAuthenticationModal } = useAccountOpenModal()
    const handleChangeAuthentication = () => {
      openChangeAuthenticationModal(() => {
        router.push({ name: 'base.admin.twoFactorAuthenticationFlow' })
      })
    }
    const handle2fa = () => {
      openDisableTwoFactorAuthenticationModal(() => {
        setTimeout(() => {
          enable2fa.value = !enable2fa.value
        }, 2000)
      })
    }

    return {
      openBackupVerificationCodesModal,
      handleChangeAuthentication,
      handle2fa,
      enable2fa,
      ips,
    }
  },
})
