export const ips = [
  {
    ip: '192.1.3.533',
    country: {
      id: 'us',
    },
  },
  {
    ip: '102.456.247.180',
    country: {
      id: 'us',
    },
  },
  {
    ip: '101.326.770.188',
    country: {
      id: 'fr',
    },
  },
]
