import { useModals } from '@/compositions/modals'
import type { User } from '@/definitions/_general/_types/types'
import type { TextArray } from '@/definitions/shared/types'

type UseAccountOpenModal = () => {
  openUserResetPassModal: (user: string, users?: User[]) => void
  openUserLogOutModal: (fullname: string) => void
  openChangePasswordModal: () => void
  openActivateUserModal: (fullname: string) => void
  openDeactivateAccountModal: () => void
  openCancelInvitationModal: (email: string) => void
  openEditTagsModal: (modalTitle: string, label: string, placeholder: string, description: string, value: []) => void
  openResendInvitationModal: (email: string) => void
  openChangeAuthenticationModal: (clickEvent: () => any) => void
  openBackupVerificationCodesModal: () => void
  openDisableTwoFactorAuthenticationModal: (clickEvent: () => any) => void
  openResetPasswordModal: () => void
  openEditNameModal: () => void
  open2FADisableModal: (clickEvent: () => any) => void
  open2FAModal: (clickEvent: () => any) => void
  openWorkspaceDeleteModal: () => void
  openWhiteListModal: (clickEvent: () => any) => void
  openSessionExpirationModal: () => void
  openWorkspacePasswordPolicyModal: () => void
  openUserLoginsModal: () => void
}

const useAccountOpenModal: UseAccountOpenModal = () => {
  const { openModal } = useModals()

  const openUserResetPassModal = (user: string, users?: User[]) => {
    let text: string | TextArray[] = 'An email with a password reset link will be sent to your email address. After following the link, you will be prompted to create a new password.'
    const userSingle = (users && users.length) ? `${users[0].person.firstName} ${users[0].person.lastName}` : user
    if (users && users.length > 1) {
      text = [
        'An email with a password reset link will be sent to ',
        { text: `${users.length} users`, style: 'semi-bold' },
        '. After following the link, the users will be prompted to create a new password.',
      ]
    }
    if (user || (users && users.length === 1)) {
      text = [
        'An email with a password reset link will be sent to ',
        { text: userSingle, style: 'semi-bold' },
        '. After following the link, the user will be prompted to create a new password.',
      ]
    }

    openModal('confirmation', {
      title: 'Reset password by email',
      text,
      btnText: 'Reset password',
    })
  }

  const openUserLogOutModal = (fullname: string) => {
    if (fullname) {
      openModal('singleCheckbox', {
        modalTitle: 'Log out from all devices',
        text: [
          'After confirming this action, the user ',
          {
            text: fullname,
            style: 'semi-bold',
          },
          ' will be immediately logged out from all devices. Are you sure you would like to continue?',
        ],
        label: `I understand that ${fullname} will be immediately logged out.`,
        btnText: 'Log out',
        btnColor: 'red',
      })
    } else {
      openModal('confirmation', {
        title: 'Log out from all devices',
        text: 'By confirming this action, you will be logged out from all devices except this one. Are you sure you would like to proceed?',
        btnText: 'Log out from all devices',
      })
    }
  }

  const openChangePasswordModal = () => {
    openModal('changePassword')
  }

  const openActivateUserModal = (fullName: string) => {
    openModal('confirmation', {
      title: 'Activate user',
      text: [
        {
          text: fullName,
          style: 'semi-bold',
        },
        ' will be reactivated in their previously set role and teams. Are you sure, you would like to reactivate this user?',
      ],
      btnText: 'Activate',
      btnColor: 'primary',
    })
  }

  const openDeactivateAccountModal = () => {
    openModal('confirmation', {
      title: 'Deactivate my account',
      text: [
        {
          text: 'We are very sorry to see that you have decided to leave us. If there is anything we can do to win you back, ',
        },
        {
          text: 'please contact our customer support',
          link: '/',
        },
        {
          text: ' Are you sure you would like to deactivate your account in this workspace?',
        },
      ],
      btnText: 'Deactivate',
      btnColor: 'red',
    })
  }

  const openCancelInvitationModal = (email: string) => openModal('confirmation', {
    title: 'Cancel invitation',
    text: [
      'By cancelling invitation, the invited user ',
      {
        text: email,
        style: 'semi-bold',
      },
      ' will not be able to set up their account in your workspace and will be removed from the list of users.\n\n',
      'Are you sure you would like to cancel the invitation?',
    ],
    btnText: 'Cancel invitation',
    btnColor: 'red',
  })

  const openEditTagsModal = (modalTitle: string, label: string, placeholder: string, description: string, value: []) => {
    openModal('singleTags', {
      modalTitle: modalTitle,
      label: label,
      placeholder: placeholder,
      description: description,
      btnText: 'Save changes',
      value: value,
    })
  }

  const openResendInvitationModal = (email: string) => openModal('singleInput', {
    modalTitle: 'Resend invitation',
    description: [
      'You’re about to resend the invitation email to ',
      {
        text: email,
        style: 'semi-bold',
      },
      '. You can change email address for this user if needed.',
    ],
    btnText: 'Resend invitation',
    label: 'User email address',
    value: email,
    placeholder: 'Enter user email address',
    errorHint: 'Please enter user email address to resend the invitation.',
    required: true,
  })

  const openChangeAuthenticationModal = (clickEvent: () => any) => {
    openModal('confirmation', {
      title: 'Change authentication method',
      text: 'Changing the authentication method will reset your previous 2FA settings. Are you sure you would like to continue?',
      btnText: 'Continue',
      clickEvent: clickEvent,
    })
  }

  const openBackupVerificationCodesModal = () => {
    openModal('backupVerificationCodes')
  }

  const openDisableTwoFactorAuthenticationModal = (clickEvent: () => any) => {
    openModal('confirmation', {
      title: 'Disable two-factor authentication',
      text: 'You’ll need to reconfigure your authentication method if you decide to start using it again. Removing two-factor authentication will also delete your unused backup codes.',
      btnText: 'Disable authentication',
      btnClickLoading: true,
      clickEvent: clickEvent,
    })
  }

  const openResetPasswordModal = () => {
    openModal('singleCheckbox', {
      modalTitle: 'Force password reset',
      text: 'All users will be immediately logged out from all devices. All ongoing chats and calls will be ended immediately. They will be prompted for new passwords via email before they can sign in again.',
      label: 'I understand that all users will be immediately logged out.',
      btnColor: 'red',
      btnText: 'Log out and force reset',
    })
  }

  const openEditNameModal = () => {
    openModal('singleInput', {
      modalTitle: 'Workspace name',
      value: 'HubSpot',
      label: 'Workspace Name',
      btnColor: 'primary',
      btnText: 'Save changes',
      hint: 'Your workspace name is displayed in menus and headings. It will usually be (or include) the name of your company.',
    })
  }
  const open2FADisableModal = (clickEvent: () => any) => {
    openModal('confirmation', {
      title: 'Disable two-factor authentication',
      text: [
        'Your workspace users ',
        {
          text: 'will not be required',
          style: 'semi-bold',
        },
        ' to set up two-factor authentication (2FA).\n\n' +
        'For existing users who have set up 2FA, it remains enabled. Users can turn 2FA on and off at any time. However, we recommend having 2FA enabled to increase security.\n\n',
        'Are you sure you would like to disable the 2FA requirement?',
      ],
      btnColor: 'primary',
      btnText: 'Disable 2FA',
      clickEvent: clickEvent,
    })
  }
  const open2FAModal = (clickEvent: () => any) => {
    openModal('confirmation', {
      title: 'Enforce two-factor authentication',
      text: [
        'Your workspace users ',
        {
          style: 'semi-bold',
          text: 'will be required',
        },
        ' to set up two-factor authentication (2FA) with their mobile phone number or an authenticator app.\n\n',
        'Existing users who don’t set up 2FA within 24 hours will be logged out from Textmagic and prompted to set up the 2FA before they can log back in. New users will be asked to set up the 2FA during signup.',
      ],
      btnColor: 'primary',
      btnText: 'Enforce 2FA',
      clickEvent: clickEvent,
    })
  }
  const openWorkspaceDeleteModal = () => {
    openModal('workspaceDelete')
  }

  const openWhiteListModal = (clickEvent: () => any) => {
    openModal('workspaceWhiteList', {
      clickEvent: clickEvent,
    })
  }
  const openSessionExpirationModal = () => {
    openModal('singleSelect', {
      modalTitle: 'Session expiration',
      description: 'Users will be automatically logged out every time after the selected inactivity period has passed since their last login. Users will need to log in again after their session has expired.',
      label: 'Session expiration period',
      options: [
        '30 days',
        '90 days',
        '120 days',
      ],
      btnText: 'Save changes',
    })
  }

  const openWorkspacePasswordPolicyModal = () => {
    openModal('workspacePasswordPolicy')
  }

  const openUserLoginsModal = () => {
    openModal('userLogins')
  }

  return {
    openUserResetPassModal,
    openUserLogOutModal,
    openChangePasswordModal,
    openActivateUserModal,
    openDeactivateAccountModal,
    openCancelInvitationModal,
    openEditTagsModal,
    openResendInvitationModal,
    openChangeAuthenticationModal,
    openBackupVerificationCodesModal,
    openDisableTwoFactorAuthenticationModal,
    openResetPasswordModal,
    openEditNameModal,
    open2FADisableModal,
    open2FAModal,
    openWorkspaceDeleteModal,
    openWhiteListModal,
    openSessionExpirationModal,
    openWorkspacePasswordPolicyModal,
    openUserLoginsModal,
  }
}

export default useAccountOpenModal
