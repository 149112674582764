
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import LoginHistoryTable from '@/components/pages/account/profile/LoginHistoryTable.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'
import type { TableHeaders } from '@/definitions/shared/types'
import type { TableItemType } from '@/definitions/account/profile/types'
import { useModals } from '@/compositions/modals'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    LoginHistoryTable,
  },
  setup() {
    const { openModal } = useModals()
    const { openUserLogOutModal } = useAccountOpenModal()

    const historyHeaders = ref<TableHeaders[]>([
      { text: 'Country', value: 'country', width: '30%' },
      { text: 'IP', value: 'ip', hideSortable: true },
      { text: 'Login date', value: 'date-created', format: (val: string) => formatDate(val) },
    ])
    const history = ref<TableItemType[]>(getTableData('loginHistory'))

    return {
      openModal,
      openUserLogOutModal,
      historyHeaders,
      history,
    }
  },
})
