
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
  },
  setup() {
    const { openUserResetPassModal, openChangePasswordModal } = useAccountOpenModal()
    return {
      openUserResetPassModal,
      openChangePasswordModal,
    }
  },
})
